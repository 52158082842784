<template>
  <div>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="product.product_statistics_available_for_sale"
      @click-clear-filter="clearFilter"
      reloadable
      @click-reload="refresh"
      resource=""
      noClickRow
    >
      <template #append-actions="{ item }">
        <TButtonQuickView @click="view(item)" class="mx-auto" />
        <TButtonEdit @click="edit(item)" class="mx-auto" />
      </template>
      <template #item="{ item }">
        <td>
          <SCardProductItems
            :item="item.product"
            resource="/sale/goods/products"
          />
        </td>
      </template>
      <template #purchase="{ item }">
        <td class="text-truncate">
          <div v-for="purchase in item.purchases" :key="purchase.id" class="">
            <div
              class="d-flex mb-2 bg-white p-1 rounded"
              v-if="
                purchase.items.find((x) => x.product_id == item.product_id) &&
                purchase.items.find((x) => x.product_id == item.product_id)
                  .remaining_distributed_quantity > 0
              "
            >
              <TLink
                :content="purchase.id"
                :to="lodash.getReferenceLink('purchase', purchase.id)"
                :messageOptions="{ bold: true }"
              />

              <TMessage
                bold
                :content="
                  purchase.buyer ? purchase.buyer.name : purchase.buyer_id
                "
                noTranslate
                class="ml-1"
              >
                <template #suffix>)</template>
                <template #prefix>(</template>
              </TMessage>
              <span class="mx-1">-</span>
              <TMessage content="Quantity" />:
              <TMessageNumber
                class="ml-1 font-weight-bold"
                :value="
                  purchase.items.find((x) => x.product_id == item.product_id)
                    .remaining_distributed_quantity
                "
              />
            </div>
          </div>
        </td>
      </template>
      <template #input="{ item }">
        <td>
          <TMessage size="h6" :content="item.input" bold noTranslate />
        </td>
      </template>
      <template #item-filter>
        <TInputText
          :value.sync="filter['product_id']"
          @update:value="filterChange"
          placeholder="Jancode"
        />
      </template>
    </TTableAdvance>
    <SModalUpdateProductSupplier
      v-if="productSelected"
      :show.sync="showModalEdit"
      :product_id="productSelected"
      @updated="filterChange"
    />
    <Inventory
      v-if="productViewSelected"
      :show.sync="showModalView"
      :product_id="productViewSelected"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Inventory from "./components/Inventory.vue";
import organizationStore from "@/core/services/tomoni/organization.local";
export default {
  components: { Inventory },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        { key: "item", label: "Product", _style: "min-width: 450px" },
        {
          key: "input",
          label: "Quantity for sale",
        },
        {
          key: "purchase",
          label: "Purchase order",
          _classes: "text-nowrap",
          _style: "width: 570px; min-width: 570px",
        },
      ],
      filter: {},
      filterSupplier: "",
      productSelected: "",
      productViewSelected: "",
      showModalEdit: false,
      showModalView: false,
    };
  },
  beforeMount() {
    this.setStoreFilter();
  },
  mounted() {
    this.$store.dispatch(
      "product.product_statistics_available_for_sale.fetch.if-first-time"
    );
  },
  computed: {
    ...mapGetters({
      list: "product.product_statistics_available_for_sale.list",
    }),
  },
  methods: {
    refresh() {
      this.$store.dispatch(
        "product.product_statistics_available_for_sale.fetch"
      );
    },
    edit(item) {
      this.productSelected = item.product_id;
      this.showModalEdit = true;
    },
    view(item) {
      this.productViewSelected = item.product_id;
      this.showModalView = true;
    },
    setStoreFilter() {
      let defaultFilter = {
        // "filter_between[input]": "0,infinity",
        "filter[organization_id]": organizationStore.get(),
      };
      const filterList = this.lodash.mapKeys(
        this.filter,
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const query = this.lodash.pickBy({
        ...filterList,
        ...defaultFilter,
      });

      this.$store.commit(
        "product.product_statistics_available_for_sale.reset-query"
      );
      this.$store.commit(
        "product.product_statistics_available_for_sale.push-query",
        query
      );
    },
    filterChange() {
      this.setStoreFilter();
      this.refresh();
    },
    clearFilter() {
      this.filter = {};
      this.filterSupplier = "";
      this.filterChange();
    },
    updateFilterSupplier(value) {
      this.filterSupplier = value;
      if (value == "empty") {
        this.filter["empty_suppliers"] = 1;
        this.filter["suppliers.id"] = "";
      } else {
        this.filter["suppliers.id"] = value;
        this.filter["empty_suppliers"] = "";
      }
    },
  },
};
</script>
