<template>
  <TModal
    title="Purchase inventory"
    :show="show"
    @update:show="$emit('update:show', $event)"
    size="xl"
  >
    <template #actions>
      <div></div>
    </template>
    <template #title>
      <TMessage content="Product" bold class="h5 my-auto">
        <template #suffix>
          <span>: {{ product_id }}</span>
        </template>
      </TMessage>
    </template>
    <TTableAdvance
      :items="list"
      :fields="fields"
      noPaginate
      store="order.purchase_items_inventory"
      resource=""
      noFilter
      noClickRow
    >
      <template #purchase_id="{ item }">
        <td>
          <TLink
            :content="item.purchase_id"
            :to="lodash.getReferenceLink('purchase', item.purchase_id)"
            :messageOptions="{ bold: true }"
          />
        </td>
      </template>
      <template #buyer_id="{ item }">
        <td>
          <TLink
            v-if="item.buyer"
            :content="item.buyer.name"
            :to="lodash.getReferenceLink('user', item.buyer.id)"
          />
        </td>
      </template>
      <template #price="{ item }">
        <td>
          <TMessageMoney :amount="item.price" :currency="item.currency_id" />
        </td>
      </template>
      <template #remaining_distributed_quantity="{ item }">
        <td>
          <TMessageNumber
            :value="item.remaining_distributed_quantity"
            class="font-weight-bold"
          />
        </td>
      </template>
      <template #amount="{ item }">
        <td>
          <TMessageMoney :amount="item.amount" :currency="item.currency_id" />
        </td>
      </template>
    </TTableAdvance>
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    product_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "purchase_id",
          label: "Purchase order",
          _style: "width: 80px; min-width: 80px",
        },
        {
          key: "buyer_id",
          label: "Buyer",
          _style: "width: 200px; min-width: 200px",
        },
        { key: "price", label: "Price" },
        {
          key: "quantity",
          label: "Quantity",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "distributed_quantity",
          label: "Distributed",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "remaining_distributed_quantity",
          label: "Remaining quantity",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "amount",
          label: "Amount",
          _style: "width: 250px; min-width: 250px",
        },
      ],
    };
  },
  watch: {
    product_id: {
      immediate: true,
      handler(value) {
        if (value && this.show) {
          this.$store.dispatch("order.purchase_items_inventory.apply-query", {
            "filter[product_id]": value,
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "order.purchase_items_inventory.list",
    }),
  },
};
</script>
